<template>
  <div>
    <div class="backClass"><a-button type="primary" @click="handleCancel"> 返回 </a-button></div>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :labelCol="{ style: 'width: 100px' }"
      :wrapper-col="{ span: 8 }"
      :rules="rules"
    >
      <a-form-model-item label="魔方组标题" prop="rubikTitle">
        <a-input placeholder="请输入魔方组标题" :max-length="8" v-model.trim="form.rubikTitle" />
      </a-form-model-item>
      <a-form-model-item label="副标题" prop="rubikSubtitle">
        <a-input placeholder="请输入魔方副标题" :max-length="15" v-model.trim="form.rubikSubtitle" />
      </a-form-model-item>
      <a-form-model-item label="标题图标" prop="rubikIcon">
        <a-upload
          name="file"
          list-type="picture-card"
          :action="IMG_API + '/oss/files'"
          class="avatar-uploader"
          :file-list="form.rubikIcon"
          @change="handleChangeImg"
          @preview="handlePreview"
          :before-upload="imgBeforeUpload"
        >
          <div v-if="this.form.rubikIcon.length < 1">
            <a-icon type="plus" />
            <div class="ant-upload-text">Upload</div>
          </div>
        </a-upload>
        <p class="tip">请上传比例1:1，小于500k，格式为PNG、JPG的图片</p>
      </a-form-model-item>
      <a-form-model-item label="魔方类型" prop="rubikType">
        <a-select
          v-model="form.rubikType"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          @change="mftypeChange"
        >
          <a-select-option v-for="option in typeList" :key="option.value" :value="option.value">
            {{ option.label }}
          </a-select-option>
        </a-select>
        <div class="mfImg">
          <img v-show="imgUrl" :src="imgUrl" />
        </div>
      </a-form-model-item>
      <a-form-model-item label="备注">
        <div class="textareaBox">
          <a-textarea
            @input="descInput"
            :max-length="20"
            v-model.trim="form.remark"
            placeholder="请输入描述内容"
            :auto-size="{ minRows: 2, maxRows: 2 }"
          />
          <span class="numberV">{{ txtVal }}/20</span>
        </div>
      </a-form-model-item>
      <a-form-model-item
        v-show="form.rubikType"
        :wrapper-col="{ span: 16, offset: 0 }"
        label="信息填写"
        prop="information"
      >
        <div class="informationBox">
          <!-- 信息填写展示组件 -->
          <div v-for="item in contentList">
            <contentMessage
              ref="contentMessage"
              :serviceList="serviceList"
              :form="item"
              @contentMessage="contentMessage"
            ></contentMessage>
          </div>
        </div>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 6, offset: 12 }">
        <a-button type="primary" :loading="onSubmitLoading" @click="handleOk"> 提交 </a-button>
      </a-form-model-item>
    </a-form-model>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewHandleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
import * as api from "@/api/cubeModuleManage";
import moment from "moment";
import { IMG_API } from "@/config/index";
import { PRE_FILE_PATH } from "@/config/index";
import contentMessage from "../components/content.vue"; //信息填写子组件
export default {
  components: {
    contentMessage,
  },
  data() {
    return {
      checkSize: false, //校验图片大小
      onSubmitLoading: false, //提交loading
      IMG_API: IMG_API, //获取上传域名
      previewVisible: false,
      previewImage: "",
      typeList: [
        //魔方类型下拉值
        {
          label: "一左一右",
          value: 1,
          img: PRE_FILE_PATH + "/picture_prod/042788122e010000_20220104-113831.png",
          content: [
            {
              title: "左一", //子组件标题
              width: 340, //图片宽度
              height: 420, //图片高度
              locationType: "A1", //模块位置类型
              rubikModelType: 1, // 魔方模块类型
              serviceId: "", //服务ID
              h5Url: "", //H5链接
              img: [], //图片路径
              whetherToVerify: true // 是否要校验
            },
            {
              title: "右一", //子组件标题
              width: 340, //图片宽度
              height: 420, //图片高度
              locationType: "A2",
              rubikModelType: 1, // 魔方模块类型
              serviceId: "", //服务ID
              h5Url: "", //H5链接
              img: [], //图片路径
              whetherToVerify: true // 是否要校验
            },
          ],
        },
        {
          label: "一左二右",
          value: 2,
          img: PRE_FILE_PATH + "/picture_prod/042787f0c9810000_20220104-113817.png",
          content: [
            {
              title: "左一", //子组件标题
              locationType: "B1",
              width: 340, //图片宽度
              height: 420, //图片高度
              rubikModelType: 1, // 魔方模块类型
              serviceId: "", //服务ID
              h5Url: "", //H5链接
              img: [], //图片路径
              whetherToVerify: true // 是否要校验
            },
            {
              title: "右一", //子组件标题
              locationType: "B2",
              width: 340, //图片宽度
              height: 205, //图片高度
              rubikModelType: 1, // 魔方模块类型
              serviceId: "", //服务ID
              h5Url: "", //H5链接
              img: [], //图片路径
              whetherToVerify: true // 是否要校验
            },
            {
              title: "右二", //子组件标题
              locationType: "B3",
              width: 340, //图片宽度
              height: 205, //图片高度
              rubikModelType: 1, // 魔方模块类型
              serviceId: "", //服务ID
              h5Url: "", //H5链接
              img: [], //图片路径
              whetherToVerify: true // 是否要校验
            },
          ],
        },
        {
          label: "两左一右",
          value: 4,
          img: PRE_FILE_PATH + "/picture_prod/042787feb1810000_20220104-113824.png",
          content: [
            {
              title: "左一", //子组件标题
              locationType: "C1",
              width: 340, //图片宽度
              height: 205, //图片高度
              rubikModelType: 1, // 魔方模块类型
              serviceId: "", //服务ID
              h5Url: "", //H5链接
              img: [], //图片路径
              whetherToVerify: true // 是否要校验
            },
            {
              title: "左二", //子组件标题
              locationType: "C2",
              width: 340, //图片宽度
              height: 205, //图片高度
              rubikModelType: 1, // 魔方模块类型
              serviceId: "", //服务ID
              h5Url: "", //H5链接
              img: [], //图片路径
              whetherToVerify: true // 是否要校验
            },
            {
              title: "右一", //子组件标题
              locationType: "C3",
              width: 340, //图片宽度
              height: 420, //图片高度
              rubikModelType: 1, // 魔方模块类型
              serviceId: "", //服务ID
              h5Url: "", //H5链接
              img: [], //图片路径
              whetherToVerify: true // 是否要校验
            },
          ],
        },
        {
          label: "一左三右",
          value: 3,
          img: PRE_FILE_PATH + "/picture_prod/042787d96f410000_20220104-113756.png",
          content: [
            {
              title: "左一", //子组件标题
              locationType: "D1",
              width: 340, //图片宽度
              height: 420, //图片高度
              rubikModelType: 1, // 魔方模块类型
              serviceId: "", //服务ID
              h5Url: "", //H5链接
              img: [], //图片路径
              whetherToVerify: true // 是否要校验
            },
            {
              title: "右一", //子组件标题
              locationType: "D2",
              width: 340, //图片宽度
              height: 205, //图片高度
              rubikModelType: 1, // 魔方模块类型
              serviceId: "", //服务ID
              h5Url: "", //H5链接
              img: [], //图片路径
              whetherToVerify: true // 是否要校验
            },
            {
              title: "右二", //子组件标题
              locationType: "D3",
              width: 165, //图片宽度
              height: 205, //图片高度
              rubikModelType: 1, // 魔方模块类型
              serviceId: "", //服务ID
              h5Url: "", //H5链接
              img: [], //图片路径
              whetherToVerify: true // 是否要校验
            },
            {
              title: "右三", //子组件标题
              locationType: "D4",
              width: 165, //图片宽度
              height: 205, //图片高度
              rubikModelType: 1, // 魔方模块类型
              serviceId: "", //服务ID
              h5Url: "", //H5链接
              img: [], //图片路径
              whetherToVerify: true // 是否要校验
            },
          ],
        },
        {
          label: "两左两右",
          value: 5,
          img: PRE_FILE_PATH + "/picture-prod/070e8da6e8410000_%E5%B7%A6%E4%BA%8C%E5%8F%B3%E4%BA%8C.png",
          content: [
            {
              title: "左一", //子组件标题
              locationType: "E1",
              width: 336, //图片宽度
              height: 200, //图片高度
              rubikModelType: 1, // 魔方模块类型
              serviceId: "", //服务ID
              h5Url: "", //H5链接
              img: [], //图片路径
              whetherToVerify: true // 是否要校验
            },
            {
              title: "右一", //子组件标题
              locationType: "E2",
              width: 336, //图片宽度
              height: 200, //图片高度
              rubikModelType: 1, // 魔方模块类型
              serviceId: "", //服务ID
              h5Url: "", //H5链接
              img: [], //图片路径
              whetherToVerify: true // 是否要校验
            },
            {
              title: "左二", //子组件标题
              locationType: "E3",
              width: 336, //图片宽度
              height: 200, //图片高度
              rubikModelType: 1, // 魔方模块类型
              serviceId: "", //服务ID
              h5Url: "", //H5链接
              img: [], //图片路径
              whetherToVerify: false // 是否要校验
            },
            {
              title: "右二", //子组件标题
              locationType: "E4",
              width: 336, //图片宽度
              height: 200, //图片高度
              rubikModelType: 1, // 魔方模块类型
              serviceId: "", //服务ID
              h5Url: "", //H5链接
              img: [], //图片路径
              whetherToVerify: false // 是否要校验
            },
          ],
        }
      ], //魔方类型
      imgUrl: "", //魔方类型对应图片
      contentList: [], //信息填写
      contentValueList: [], //信息填写提交值
      detailId: "", //详情id
      txtVal: 0, //textarea标签输入的文字个数
      serviceList: [], //服务列表
      form: {
        rubikTitle: "", //魔方标题
        rubikSubtitle: "", //魔方副标题
        rubikType: undefined, //魔方类型
        rubikModelList: [], //魔方模块列表
        remark: "", //备注
        rubikIcon: [], //标题图标
        information: "1",
      },
      rules: {
        rubikTitle: [{ required: true, message: "请输入魔方标题", trigger: "blur" }],
        rubikSubtitle: [{ required: true, message: "请输入魔方副标题", trigger: "blur" }],
        rubikType: [{ required: true, message: "请选择魔方类型", trigger: "blur" }],
        // rubikIcon: [{ required: true, message: "请添加标题图标", trigger: "change" }],
        information: [{ required: true, message: "", trigger: "change" }],
      },
    };
  },
  created() {
    moment(moment()).unix();
    this.getSelectList();
    this.getServiceList();
  },
  mounted() {
    if (this.$route.query.id) {
      this.getDetail();
    }
  },
  methods: {
    //获取详情
    async getDetail() {
      const res = await api.selectrubikdetailsbyid({ id: this.$route.query.id });
      if (res.code === "200") {
        this.form = res.data;
        this.form.id = this.$route.query.id;
        this.form.information = "1";
        if (this.form.rubikIcon) {
          let iconImg = {
            uid: "1",
            name: "image.png",
            status: "done",
            url: this.form.rubikIcon,
          };
          this.form.rubikIcon = [iconImg];
        } else {
          this.form.rubikIcon = [];
        }
        this.form.information = "1";
        //信息填写模块赋值
        let DataValue = this.typeList[res.data.rubikType - 1];
        res.data.rubikModelList.forEach((item) => {
          DataValue.content.forEach((element) => {
            //信息填写标题赋值
            if (element.locationType === item.locationType) {
              item.title = element.title;
              item.width = element.width;
              item.height = element.height;
            }
          });
          var obj = item.img ? [{
            uid: "1",
            name: "image.png",
            status: "done",
            url: item.img,
          }] : []
          item.img = obj;
        });
        //备注输入框的字符个数
        this.txtVal = res.data.remark.length;
        //魔方类型图片回显
        this.imgUrl = this.typeList[res.data.rubikType - 1].img;
        //信息填写赋值
        this.contentList = res.data.rubikModelList.map(item => {
          return {
            ...item,
            whetherToVerify: (!(item.locationType === 'E3' || item.locationType === 'E4'))
          }
        })
      }
    },
    //获取服务列表
    async getServiceList() {
      const res = await api.selectservicelistbyname();
      if (res.code === "200") {
        res.data.forEach((item) => {
          item.serviceName = item.serviceName + " " + item.id;
        });
        this.serviceList = res.data;
      }
    },
    //获取魔方类型下拉值
    async getSelectList() {
      const res = await api.selectrubiktype();
      if (res.code === "200") {
        //魔法类型数据匹配
        res.data.forEach((item) => {
          this.typeList.forEach((element) => {
            if (item.rubikType === element.value) {
              element.label = item.rubikTypeName;
            }
          });
        });
      }
    },
    moment,
    //提交数据校验
    async handleOk() {
      // 触发组件的校验规则
      let that = this;
      new Promise(function (resolve, reject) {
        //清空提交信息
        that.contentValueList = [];
        //触发每个组件的校验事件
        that.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            // 校验每个组件的必填项
            if (that.$refs.contentMessage) {
              that.$refs.contentMessage.forEach(async (item) => {
                await item.parentHandleclick();
              });
              resolve();
            }
          } else {
            reject()
            return false
          }
        });
      }).then(() => {
        let type = that.contentValueList.every((item) => {
          return item.type === true;
        });
        // 如果模块填了url地址，则图片必须上传
        const flag = that.contentList.some(item => item.h5Url && !item.img.length && !item.whetherToVerify)
        if (flag) {
          this.$message.warning('请您上传非必填模块已配置【链接地址】数据的对应图片')
          return false;
        }
        if (type) {
          that.onSubmitLoading = true;
          // 判断是添加还是编辑
          if (that.$route.query.id) {
            that.submitOldData();
          } else {
            that.submitNewData();
          }
        }
      });
    },
    //提交编辑
    async submitOldData() {
      let formData = JSON.parse(JSON.stringify(this.form));
      let list = [];
      //信息填写赋值
      this.contentValueList.forEach((item) => {
        list.push(item.form);
      });
      formData.rubikModelList = list;
      if(this.form.rubikIcon.length > 0) {
        formData.rubikIcon = this.form.rubikIcon[0].response
        ? this.form.rubikIcon[0].response.redirect_uri
        : this.form.rubikIcon[0].url;
      } else {
        formData.rubikIcon = "";
      }
      const res = await api.updaterubikbyid(formData);
      this.onSubmitLoading = false;
      if (res.code === "200") {
        this.$message.success("提交成功!");
        this.$router.go(-1);
      } else {
        this.$message.error(res.msg);
      }
    },
    //提交新数据
    async submitNewData() {
      let formData = JSON.parse(JSON.stringify(this.form));
      let list = [];
      //信息填写赋值
      this.contentValueList.forEach((item) => {
        list.push(item.form);
      });
      if(this.form.rubikIcon.length > 0) {
        formData.rubikIcon = this.form.rubikIcon[0].response.redirect_uri;
      } else {
        formData.rubikIcon = ""
      }
      formData.rubikModelList = list;
      const res = await api.addrubik(formData);
      this.onSubmitLoading = false;
      if (res.code === "200") {
        this.$message.success("提交成功!");
        this.$router.go(-1);
      } else {
        this.$message.error(res.msg);
      }
    },
    //获取textarea标签输入的文字个数
    descInput() {
      this.txtVal = this.form.remark.length;
    },
    // 魔方类型change事件
    mftypeChange() {
      //信息填写赋值
      this.contentList = this.typeList[this.form.rubikType - 1].content;
      //信息填写类型图片展示
      this.imgUrl = this.typeList[this.form.rubikType - 1].img;
      this.contentValueList = [];
      if (this.$refs.contentMessage) {
        //清除每个组件的校验规则
        this.$refs.contentMessage.forEach(async (item) => {
          await item.clearRules();
        });
      }
    },
    //获取组件内填写值
    contentMessage(val) {
      this.contentValueList.push(val);
    },
    // 返回上一页
    handleCancel() {
      this.$router.go(-1);
    },
    //校验图片
    handleChangeImg({ fileList }) {
      if (this.checkSize) {
        this.form.rubikIcon = fileList;
      } else {
        this.form.rubikIcon = [];
      }
    },
    //上传文件之前校验图片大小
    imgBeforeUpload(file) {
      this.checkSize = false;
      let index = file.name.lastIndexOf(".");
      let type = file.name.substring(index + 1, file.name.length);
      const isJPG = type === "png" || type === "jpg";
      if (!isJPG) {
        this.$message.error("上传图片只能是jpg、png格式!");
        return false;
      }
      this.checkSize = isJPG;
      const isSize = new Promise(function (resolve, reject) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function () {
          const valid = img.width / img.height === 1;
          valid ? resolve() : reject();
        };
        img.src = URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error("图片不规范，请按要求上传");
          this.checkSize = false;
          return Promise.reject();
        }
      );
      return isJPG && isSize;
    },
    //预览
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    //弹窗图片预览关闭弹窗
    previewHandleCancel() {
      this.previewVisible = false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ant-form-item {
  display: flex;
}

.backClass {
  display: flex;
  justify-content: flex-end;
}
.mfImg {
  display: flex;
  flex-wrap: wrap-reverse;
  min-width: 400px;
}
.mfImg > img {
  height: 100px;
  padding-right: 10px;
  margin-top: 10px;
}
.textareaBox {
  position: relative;
}
.numberV {
  bottom: 0%;
  right: 3%;
  font-size: 12px;
  position: absolute;
}
.informationBox > div {
  margin-top: 40px;
}
.informationBox > div:nth-child(1) {
  margin-top: 0px;
}
.tip {
  font-size: 12px;
  line-height: 20px;
}
</style>
